.section__footer {
  position: relative;
  padding: 4rem 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.822);
  /* height: 100%; */
  /* display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column; */
  color: #fff;
}
.section__footer p {
  color: #fff;
}
.footer__containner {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.footer__containner div {
  width: 33.2;
  text-align: center;
  padding: 0.8rem 1rem;
}
.footer__containner div {
  margin-right: 1px;
}

.footer__logo p,
.footer__adress p,
.footer__contact p {
  margin-top: 2rem;
  font-size: 14px;
  /* font-family: cursive; */
}
.copyright {
  margin-top: 2rem ;
  border-top: 0.1px solid #fff;
  padding: 1rem 0;
  text-align: center;
  font-weight: 100;
  font-size: 1rem;
}
.copyright b{
  font-family: "Comic sans ms", "comic", cursive !important;
    font-weight: 100;
    color: var(--primary__color);
}
.copyright span {
  color: #fff;
  font-weight: 100;
  font-size: 12px;
}

@media (max-width: 776px) {
  .footer__containner {
    justify-content: center;
  }
  .footer__logo p,
  .footer__adress p,
  .footer__contact p {
    margin-top: 1rem;
  }
}
