button{
    border: none;
    padding:0.6rem  1rem;
    font-weight: 700;
    color: var(--white__color);
    background-color:var(--primary__color);
    cursor: pointer;   
    transition: all 0.5s ease;opacity: .8;
}
button:hover{
    opacity: 1;
}
