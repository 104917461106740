.carousel-inner,
.carousel-item,
#carouselExampleControls{
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: #111;
}
.carousel-item img{
    position: relative;
    opacity: .5;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.carousel-item .caption{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index:1;
    color: #fff;
    top:0;
    left: 0;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.carousel-item .caption h1{
    font-size:3.5rem;
}
.carousel-item .caption p{
    font-size:1.4rem;
}
