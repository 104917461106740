* {
  margin-bottom: 0 !important;
}

.header {
  box-sizing: border-box;
  width: 100%;
  transition: all 0.5s ease;
  line-height: 1.5rem;
  transition: all 0.5s ease;
}

.header .header__navbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  padding: 0px 40px !important;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  transition: all 0.5s ease;
  background-color: #fff;
  color: #111;
  /* background-color: rgba(255, 255, 255, 0.4);*/
/* 
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(219, 203, 203, 0.4);  */
}
.header .header__navbar.stick {
  padding: 0px 45px !important;

  transition: all 0.5s ease;
}
.header .header__navbar.stick nav ul li a {
  color: #111;
}
.logo {
  width: 140px;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 15px;
  transition: all 0.5s ease;
}

.logo img {
  width: 100%;
}

nav ul li {
  display: inline-block;
  margin-left: 4px !important;
  transition: all 0.5s ease;
}

nav ul li a {
  font-weight: 500;
  text-decoration: none;
  letter-spacing: 1px;
  color: var(--dark__color);
  transition: all 0.5s ease;
  padding: 0.3rem 0.5rem;
  cursor: pointer;
}
nav ul li a.active,
nav ul li a:hover {
  color: var(--primary__color) !important;
}

.mobile__menu {
  height: 40px;
  background-color: transparent;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  display: none;
  transition: all 0.5s ease;
}
.mobile__menu span {
  display: block;
  padding: 0.1rem 1rem;
  background-color: var(--dark__color);
}
.mobile__menu:hover {
  color: var(--dark__color);
}
@media (max-width: 810px) {
  .header .header__navbar nav ul li a {
    color: #111;
  }

  .header .header__navbar,
  .header .header__navbar.stick {
    padding: 0 !important;
    background-color: #fff;
  }
  nav {
    width: 100%;
    display: none;
  }
  nav.open {
    position: relative;
    z-index: 5;
    display: block;
    color: black;
  }
  nav ul {
    padding: 1rem;
    width: 100%;
    border-top: 2px solid #111;
    padding-bottom: 2rem;
  }
  nav ul li {
    width: 100%;
    display: block;
    margin: 1rem 0;
    padding: 0;
  }
  .mobile__menu {
    display: flex;
  }
}
