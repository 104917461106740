textarea,
input{
    outline: none;
}
.wrapper__input{
    margin-top: 1rem;
}

.input{
    padding: .6rem;
    /* background-color: rgb(255, 255, 255); */
    background-color: #ddd;
    border: none;
    width: 100%;
}
