#contact{
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, .9),
        rgba(255, 255, 255, .9)
      ),
      url("https://images.unsplash.com/photo-1488085061387-422e29b40080?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fHRyYXZlbHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=1024&q=60");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
}