@import url("https://fonts.googleapis.com/css2?family=Comic+Neue:wght@300;400;700&display=swap");
@font-face {
  font-family: "comic";
  src: url("../public/font/comic.TTF");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  list-style: none;
  font-size: 16px;
}

:root {
  --dark__color: #111;
  --white__color: #fff;
  --primary__color: #f11717;
}

body {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

header {
  position: relative;
  margin-bottom: 2rem;
}

header h1 {
  font-family: "Comic sans ms", "comic", cursive !important;
  letter-spacing: 2px;
  color: var(--primary__color);
  font-weight: 700;
  margin-bottom: 2rem;
}

header span {
  font-size: 18px;
  font-weight: 500;
}

hr {
  position: relative;
  top: 40%;
  left: 50%;
  transform: translate(-50%);
  background-color: var(--primary__color);
  width: 10%;
  text-align: center;
  padding: 1.5px 6rem;
  opacity: 1;
  border: none;
  text-align: center;
}

.subtitle {
  color: var(--dark__color);
  font-weight: 700;
  margin-bottom: 3rem;
  padding: 2rem 0;
  border-bottom: 0.1rem solid var(--primary__color);
}
@media (max-width: 660px) {
  hr {
    top: 26%;
  }
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */ 
::-webkit-scrollbar-track { 
  /* background: 0px; */
}

/* Handle */ 
::-webkit-scrollbar-thumb { 
  background: rgb(139, 135, 135);
}

/* Handle on hover */ 
::-webkit-scrollbar-thumb:hover { 

  background: rgb(22, 22, 22);
}
