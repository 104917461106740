#service {
  min-height: 100vh;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    url("https://cdn.pixabay.com/photo/2016/01/31/21/38/port-1172267_960_720.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
  background-color: #f8f9fa;
  padding: 0 18px;
  position: relative;
}
.subtitle{
  border:none;
  font-weight: 500;
  margin-bottom: 0;
}