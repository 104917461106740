#location {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    url("https://images.unsplash.com/photo-1488085061387-422e29b40080?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fHRyYXZlbHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=1024&q=60");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}

.location__tabs {
  display: none;
  opacity: 0;
  transition: all .5s;
}
.location__tabs.active {
  display: flex;
  opacity: 1;
}
button.btn__location.active{
  background-color: var(--primary__color);
  opacity: 1;
}
button.btn__location{
  background-color: rgba(26, 22, 22, 0.466);
  border: none;
  border-radius:2rem;
  font-weight: 200;
  transition: backgroundColor .5s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

}
i{
  margin-left: .5rem;
}
button.btn__location:hover{
  background-color: var(--primary__color);
}

.row{
  position: relative;  transition: all .5s;
}
.location__tabs{
 position: absolute;  transition: all .5s;
 z-index: 1;
}
@media (min-width:776px) {
  button.btn__location{
    margin-left: 1rem;
  }
}