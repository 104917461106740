
.goToTop{
    position: fixed;
    z-index: 55;
    right: 1rem;
    bottom: 1rem;
    background-color: var(--primary__color);
    cursor: pointer;
    padding: .5rem;
    transition: all 0.5s;
    color: #fff;
    font-size: 10px;
    text-align: center ;
    display: none;
  }
  .goToTop:hover{
    background-color: var(--dark__color);
  }